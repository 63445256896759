<template>
  <div>
    <c-table
      ref="workerTable"
      isTitle
      title="작업자명"
      :columns="grid.columns"
      :gridHeightAuto="true"
      :data="workPermit.workers"
      :merge="grid.merge"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&isWriting"
      selection="multiple"
      rowKey="sopWorkerId"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&isWriting" label="업체" icon="add" @btnClicked="addVendorUser" />
          <c-btn v-if="editable&&isWriting" label="현업" icon="add" @btnClicked="addUser" />
          <c-btn v-if="editable&&isWriting" label="제외" icon="remove" :showLoading="false" @btnClicked="remove" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
export default {
  name: 'swp-contractor',
  props: {
    workPermit: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
          plantCd: '',  // 사업장 코드
          sopName: '',  // 작업명
          mdmSopId: '',  // 안전작업 표준 일련번호_SOP에서 불러와서 관련 정보 표시후 수정
          permitNo: '',  // 허가번호_(YYYYMMDD-01)
          swpStepCd: '',  // 진행단계 코드
          applicationDeptCd: '',  // 신청인 부서코드
          applicationDeptName: '',
          applicationUserId: '',  // 신청인 ID
          applicationUserName: '',
          permitTypeCd: '',  // 허가서 구분_화기/일반
          permitDate: '',  // 허가일자
          workStartTime: '', // 작업시작시간
          workEndTime: '', // 작업종료시간
          workTime: [], // 작업시간
          processCd: '',  // 작업공정
          workLocation: '',  // 작업장소
          equipmentCd: '',  // 설비번호_설비마스터
          workSummary: '',  // 작업개요
          specialRequirements: '',  // 특별요구사항
          maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
          maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
          workCompleteTime: '',  // 작업완료시간
          workCompleteEntryUserId: '',  // 작업완료 입회자/확인자
          workCompleteWorkUserId: '',  // 작업완료 작업자
          issuedDeptCd: '',  // 발급자 부서코드
          issuedUserId: '',  // 발급자 ID
          approvalDeptCd: '',  // 승인자 부서코드
          approvalUserId: '',  // 승인자 ID
          relationCooperation1DeptCd: '',  // 관련부서 협조자1 부서코드
          relationCooperation1UserId: '',  // 관련부서 협조자1 ID
          relationCooperation2DeptCd: '',  // 관련부서 협조자2 부서코드
          relationCooperation2UserId: '',  // 관련부서 협조자2 ID
          vendorCd: '',  // 작업업체 코드
          overWorkFlag: '',  // 연장작업 여부
          psmFlag: 'N',  // PSM 관련 여부
          sopMocId: '',  // MOC 일련번호
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
          supWorks: [],
          deleteSupWorks: [],
          checkResults: [],
          gases: [],
          gasChecks: [], // 저장용
          gasCheckVals: [], // 저장용
          deleteGasChecks: [],
          deleteGasCheckVals: [],
          maps: [],
          deleteMaps: [],
          workers: [],
          deleteWorkers: [],
          overs: [],
          deleteOvers: [],
          protectiveGears: [],
          deleteProtectiveGears: [],
        }
      }
    },
    isWriting: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        merge: [
          { index: 0, colName: 'belongName' }
        ],
        columns: [
          {
            name: 'belongName',
            field: 'belongName',
            label: '소속',
            align: 'center',
            sortable: false,
          },
          {
            name: 'workerName',
            field: 'workerName',
            label: '성명',
            align: 'center',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            align: 'center',
            sortable: false,
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            label: '생년월일',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
        height: '350px'
      },
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    height() {
      this.setTabHeight();
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
      this.setTabHeight();
    },
    addVendorUser() {
      this.popupOptions.title = '도급업체 근무자'; 
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.workPermit.plantCd, // 사업장코드(관련사업장)
        vendorCd: this.workPermit.vendorCd // 도급업체코드
      };
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorUserPop.vue'}`);
      this.popupOptions.width = '100%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeVendorUserPopup;
    },
    closeVendorUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.workPermit.workers, { vendorUserCd: item.vendorUserCd }) === -1) {
            let idx = this.$_.findIndex(this.workPermit.workers, { vendorCd: item.vendorCd })
            idx = idx > -1 ? idx : 0;
            this.workPermit.workers.splice(idx, 0, {
              sopWorkerId: uid(),  // 안전작업허가서_작업자 일련번호
              sopWorkPermitId: this.workPermit.sopWorkPermitId,  // 작업허가서 일련번호
              belongName: '('+this.$language('업체')+' ' + item.vendorName,  // 소속명
              vendorCd: item.vendorCd,  // 작업업체 코드
              deptCd: '',  // 부서코드
              vendorUserCd: item.vendorUserCd,  // 직원 일련번호
              userId: '',  // 사용자id (현업인 경우)
              workerName: item.personName,  // 작업자명
              jobName: item.jobName,
              birthDate: item.birthDate,
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              chgUserId: '',  // 수정자 ID
              editFlag: 'C',
            })
          }
        })
      }
    },
    addUser() {
      this.popupOptions.title = '사용자 검색'; 
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '100%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.workPermit.workers, { userId: item.userId }) === -1) {
            let idx = this.$_.findIndex(this.workPermit.workers, { deptCd: item.deptCd })
            idx = idx > -1 ? idx : 0;
            this.workPermit.workers.splice(idx, 0, {
              sopWorkerId: uid(),  // 안전작업허가서_작업자 일련번호
              sopWorkPermitId: this.workPermit.sopWorkPermitId,  // 작업허가서 일련번호
              belongName: '('+this.$language('현업')+' '+ item.deptName,  // 소속명
              vendorCd:'',  // 작업업체 코드
              deptCd: item.deptCd,  // 부서코드
              vendorUserCd: '',  // 직원 일련번호
              userId: item.userId,  // 사용자id (현업인 경우)
              workerName: item.userName,  // 작업자명
              jobName: item.jobName,
              birthDate: item.birthDate,
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              chgUserId: '',  // 수정자 ID
              editFlag: 'C',
            })
          }
        })
      }
    },
    remove() {
      let selectData = this.$refs['workerTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '삭제할 행을 지정하세요.', 
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.workPermit.workers = this.$_.reject(this.workPermit.workers, item);
        });
      }
    },
    setTabHeight() {
      let returnText = '500px';
      returnText = this.height && !isNaN(Number(this.height.replace('px', ''))) ? String(Number(this.height.replace('px', '')) - 70) + 'px' : returnText;
      this.grid.height = returnText;
    },
  }
};
</script>
